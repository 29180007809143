














import { Core } from "@/store/core";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {

  },
  computed: {}
})

export default class loading extends Vue {

  overlay:any =  true


  async created() {

  }

  get loadX(){
    return Core.loading
  } 

}
