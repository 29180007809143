














import {
    Component,
    Vue,
} from 'vue-property-decorator';
import WebNav from '../../components/core/WebNav.vue'
import Footer from '../../components/core/Footer.vue'
import Loading from '../../components/core/loading.vue'
@Component({
    components: {
        WebNav,
        Footer,
        Loading
    },
    computed: {}
})

export default class Test extends Vue {

}
